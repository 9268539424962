import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography, Box, Paper, Grid, Button, Chip, LinearProgress, CircularProgress, Container, List, ListItem, ListItemText, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Link, Pagination } from '@mui/material';
import axios from 'axios';
import XIcon from '@mui/icons-material/X';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ShareIcon from '@mui/icons-material/Share';
import serBackground from '../assets/ser_bg.jpg';
import { 
  Campaign, 
  Tweet, 
  UserInteraction, 
  UserCampaignData, 
  UserData,
  Multiplier,
  TokenTier,
  LPMultiplier,
  isFungibleMultiplier,
  isLPMultiplier
} from '../types/Campaign';
import { styled } from '@mui/material/styles';
import { useWalletInterface } from '../services/wallets/useWalletInterface';
import CampaignTweets from '../components/CampaignTweets';
import { getTimeLeft } from '../utils/timeUtils';
import { useAuth } from '../contexts/AuthContext';
import { MirrorNodeClient } from '../services/wallets/mirrorNodeClient';
import { appConfig } from '../config';
import { AccountId, TokenId } from '@hashgraph/sdk';
import { getIdFromSlug } from '../utils/slugUtils';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const NetworkLogo = styled('img')({
  width: 16,
  height: 16,
  borderRadius: '50%',
  objectFit: 'cover',
  marginRight: 4,
});

const StyledTableHeaderCell = styled(TableCell)({
  fontWeight: 'bold',
  color: '#ff004e',
  borderBottom: '1px solid #ff004e',
  padding: '8px 16px', // Reduced padding - 
});

const StyledTableCell = styled(TableCell)({
  color: '#ff004e',
  borderBottom: '1px solid lightgrey',
  padding: '8px 16px', // Reduced padding
});

const mirrorNode = new MirrorNodeClient(appConfig.network);

const getHashscanLink = (txId: string) => {
  const network = appConfig.network.network;
  return `https://hashscan.io/${network}/transactionsById/${txId}`;
};

interface PrizeShare {
  token_name: string;
  token_id: string;
  amount: number;
  claimStatus?: 'claimed' | 'unclaimed';
  claimTx?: {
    txId: string;
    timestamp: string;
    amount: number;
  };
}

interface TokenGatingRequirement {
  name: string;
  tokenId: string;
  amount: number;
  type: string;
  decimals: number;
  symbol: string;
  _id?: string;
}

const formatTokenAmount = (amount: number, decimals: number = 8) => {
  return (amount / Math.pow(10, decimals)).toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: decimals
  });
};

const calculateAmountAfterFee = (amount: number | undefined, customFee: number) => {
  if (!amount) return 0;
  return amount * (1 - (customFee/100));
};

// Add these types at the top of the file
interface MultiplierObject {
  current: number;
  max: number | null;
}

interface LeaderboardEntry {
  _id: string;
  accountId: string | null;
  displayName: string | null;
  score: number | null;
  multiplier: number | MultiplierObject;
  prizes: {
    rank: any[];
    share: any[];
  };
  twitterHandle?: string;
  rank: number;
}

// Create a new interface for our component state
interface ComponentCampaignData {
  interactions: UserInteraction[];
  scores: Array<{ score: number; timestamp: Date }>;
  multiplier: number | MultiplierObject;
  prizes: {
    rank: any[];
    share: any[];
  };
  claims?: {
    [tokenId: string]: 'unclaimed' | 'pending' | 'claimed';
  };
  claimTxs?: {
    [tokenId: string]: {
      txId: string;
      timestamp: Date;
      amount: number;
    };
  };
}

// Add this helper function at the top of the file
const roundDownPrizeAmount = (amount: number): string => {
  return Math.floor(amount).toLocaleString();
};

const CampaignDetails: React.FC = () => {
  const { slugId } = useParams<{ slugId: string }>();
  const id = slugId ? getIdFromSlug(slugId) : '';
  const navigate = useNavigate();
  const [campaign, setCampaign] = useState<Campaign | null>(null);
  const [loading, setLoading] = useState(true);
  const { accountId, walletInterface } = useWalletInterface();
  const [userCampaignData, setUserCampaignData] = useState<ComponentCampaignData | null>(null);
  const [isClaimLoading, setIsClaimLoading] = useState(false);
  const [claimError, setClaimError] = useState<string | null>(null);
  const [claimTxId, setClaimTxId] = useState<string | null>(null);
  const [leaderboard, setLeaderboard] = useState<LeaderboardEntry[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isClaimable, setIsClaimable] = useState(false);
  const [userInteractions, setUserInteractions] = useState<UserInteraction[]>([]);
  const [isOptedIn, setIsOptedIn] = useState<boolean>(false);
  const [userData, setUserData] = useState<UserData | null>(null);
  const [optInError, setOptInError] = useState<string | null>(null);
  const [isOptingIn, setIsOptingIn] = useState(false);
  const { isAuthenticated } = useAuth();
  const [tokenAssociations, setTokenAssociations] = useState<Record<string, boolean>>({});
  const [tokenBalances, setTokenBalances] = useState<Record<string, number | null>>({});
  const [claimLoadingStates, setClaimLoadingStates] = useState<Record<string, boolean>>({});
  const [claimErrors, setClaimErrors] = useState<Record<string, string>>({});

  useEffect(() => {
    const fetchCampaign = async () => {
      if (!id) return;
      
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/campaigns/${id}`);
        setCampaign(response.data);
      } catch (error) {
        console.error('Error fetching campaign:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCampaign();
  }, [id]);

  useEffect(() => {
    // Reset states when campaign ID changes
    setIsOptedIn(false);
    setUserCampaignData(null);
    setUserInteractions([]);
    setIsClaimable(false);
    setClaimError(null);
    setClaimTxId(null);
  }, [id]); // Only depend on the campaign id

  const fetchUserData = async () => {
    if (!accountId || !id) {
      console.log('Missing accountId or id:', { accountId, id });
      return;
    }
    
    try {
      // First get the user data to check opt-in status
      const userResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/${accountId}`);
      
      // Check if campaign exists in optedInCampaigns
      const optedInCampaigns = userResponse.data.optedInCampaigns || [];
      console.log('Checking opt-in status:', {
        optedInCampaigns,
        campaignToCheck: id,
        campaignKey: `campaign_${id}`
      });

      // Direct array inclusion check
      const hasOptedIn = optedInCampaigns.some((campaign: { _id: string; }) => campaign._id === id);
      
      console.log('Opt-in determination:', {
        isInOptedList: hasOptedIn,
        currentCampaignId: id,
        optedInCampaigns
      });
      
      setIsOptedIn(hasOptedIn);

      // Then get campaign specific data
      const campaignResponse = await axios.get<UserData>(
        `${process.env.REACT_APP_API_URL}/api/users/${accountId}/campaigns/${id}`
      );
      
      const campaignKey = `campaign_${id}`;
      const campaignData = campaignResponse.data.campaigns_data?.[campaignKey];
      
      if (campaignData) {
        console.log('Found campaign data:', {
          hasData: true,
          claimStatus: campaignData.claimStatus,
          interactions: campaignData.interactions?.length
        });
        setUserCampaignData(campaignData ? {
          interactions: campaignData.interactions || [],
          scores: campaignData.scores || [],
          multiplier: typeof campaignData.multiplier === 'object' 
            ? campaignData.multiplier 
            : campaignData.multiplier || 1,
          prizes: {
            rank: [],
            share: campaignData.prizes?.share || []
          },
          claims: campaignData.claims,
          claimTxs: campaignData.claimTxs
        } : null);
        setUserInteractions(campaignData.interactions || []);
      } else {
        console.log('No campaign data found for:', campaignKey);
        setUserCampaignData(null);
        setUserInteractions([]);
      }

    } catch (error) {
      console.error('Error fetching user data:', error);
      setIsOptedIn(false);
      setUserCampaignData(null);
      setUserInteractions([]);
    }
  };

  useEffect(() => {
    console.log('useEffect triggered with:', { accountId, id });
    if (accountId && id) {
      fetchUserData();
    }
  }, [accountId, id]); // Add fetchUserData to dependencies if needed

  const handleClaim = async (tokenId: string) => {
    if (!accountId || !campaign) {
      console.log('Missing required data:', { accountId, campaign });
      return;
    }

    setClaimLoadingStates(prev => ({ ...prev, [tokenId]: true }));
    setClaimErrors(prev => ({ ...prev, [tokenId]: '' }));

    try {
      console.log('Attempting to claim token:', { 
        accountId, 
        campaignId: campaign._id,
        tokenId,
        userCampaignData
      });

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/campaigns/${campaign._id}/claim`,
        { accountId, tokenId }
      );

      console.log('Claim response:', response.data);
      await fetchUserData();
      
    } catch (error: any) {
      console.error('Claim error details:', {
        tokenId,
        message: error.message,
        response: error.response?.data,
        status: error.response?.status
      });
      setClaimErrors(prev => ({
        ...prev,
        [tokenId]: error.response?.data?.error || 'Failed to claim rewards'
      }));
    } finally {
      setClaimLoadingStates(prev => ({ ...prev, [tokenId]: false }));
    }
  };

  const fetchLeaderboard = async (page: number = 1) => {
    if (!id) return;
    
    try {
      setIsLoading(true);
      console.log('Fetching leaderboard for page:', page);
      
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/campaigns/${id}/leaderboard`,
        { params: { page, limit: 20 } }
      );
      
      setLeaderboard(response.data.leaderboard);
      setTotalPages(response.data.pagination.pages);
      setCurrentPage(page);
    } catch (error) {
      console.error('Error fetching leaderboard:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    fetchLeaderboard(page);
  };

  useEffect(() => {
    const checkClaimStatus = () => {
      if (!campaign || !accountId || !userCampaignData || !isOptedIn) {
        setIsClaimable(false);
        return;
      }

      const endTimeDate = typeof campaign.endTime === 'string' 
        ? new Date(campaign.endTime)
        : new Date(parseInt(campaign.endTime.$date.$numberLong));
      
      const hasEnded = endTimeDate < new Date();
      const claimingEnabled = campaign.claimAvailable === true;
      const hasPrizes = (userCampaignData.prizes?.share ?? []).length > 0;
      
      const canClaim = hasEnded && claimingEnabled && isOptedIn && hasPrizes;
      
      setIsClaimable(canClaim);
    };

    checkClaimStatus();
  }, [campaign, accountId, userCampaignData, isOptedIn]);

  const handleShare = () => {
    // Get the current URL for the campaign
    const campaignUrl = window.location.href;
    
    const tweetText = `Check out this campaign: ${campaign?.name} on @WeTheMouth\n\n${campaignUrl}`;
    const tweetUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}`;
    window.open(tweetUrl, '_blank');
  };

  const handleCreatorClick = (twitterHandle: string) => {
    window.open(`https://twitter.com/${twitterHandle.replace('@', '')}`, '_blank');
  };

  const handleOptIn = async () => {
    if (!accountId) {
      setOptInError('No wallet connected');
      return;
    }

    setIsOptingIn(true);
    setOptInError(null);

    try {
      console.log('Sending opt-in request:', { 
        accountId, 
        campaignId: id,
        url: `${process.env.REACT_APP_API_URL}/api/campaigns/${id}/opt-in`
      });

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/campaigns/${id}/opt-in`,
        { accountId },
        { 
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true 
        }
      );
      
      console.log('Opt-in response:', response.data);
      
      // Set isOptedIn to true immediately
      setIsOptedIn(true);
      
      // Then refresh user data 
      await fetchUserData();
      
    } catch (error: any) {
      console.error('Error opting in to campaign:', error);
      const errorMessage = error.response?.data?.error || 'Failed to opt in to campaign';
      setOptInError(errorMessage);
      
      // If they're already opted in, update the state accordingly
      if (errorMessage === 'Already opted in to this campaign') {
        setIsOptedIn(true);
      }
    } finally {
      setIsOptingIn(false);
    }
  };

  useEffect(() => {
    console.log('Fetching initial leaderboard data');
    fetchLeaderboard(1);
  }, [id]); // Add id as dependency

  const checkTokenAssociation = async (tokenId: string) => {
    if (!accountId) return false;
    
    try {
      const isAssociated = await mirrorNode.isAssociated(
        AccountId.fromString(accountId),
        tokenId
      );
      setTokenAssociations(prev => ({
        ...prev,
        [tokenId]: isAssociated
      }));
      return isAssociated;
    } catch (error) {
      console.error('Error checking token association:', error);
      return false;
    }
  };

  const checkTokenBalance = async (tokenId: string) => {
    if (!accountId) return null;
    
    try {
      const balance = await mirrorNode.getTokenBalance(
        AccountId.fromString(accountId),
        tokenId
      );
      console.log(`Token balance for ${tokenId}:`, balance);
      setTokenBalances(prev => ({
        ...prev,
        [tokenId]: balance
      }));
      return balance;
    } catch (error) {
      console.error('Error checking token balance:', error);
      return null;
    }
  };

  useEffect(() => {
    const checkAllTokenRequirements = async () => {
      if (!accountId || !campaign) return;

      // Check prize token associations 
      for (const prize of campaign.prizes?.sharePrizes || []) {
        if (prize.tokenId !== 'HBAR') {
          await checkTokenAssociation(prize.tokenId);
        }
      }

      // Check token gating requirements
      console.log('Checking token gating requirements');
      for (const req of campaign.tokenGating || []) {
        console.log(`Checking token ${req.tokenId} balance...`);
        const balance = await checkTokenBalance(req.tokenId);
        console.log(`Token ${req.tokenId} balance:`, balance);
      }

      // Check multiplier tokens (existing code)
      campaign?.multipliers?.forEach(multiplier => {
        if (!isLPMultiplier(multiplier) && multiplier.tokenId) {
          checkTokenBalance(multiplier.tokenId);
        }
      });
    };

    checkAllTokenRequirements();
  }, [accountId, campaign]);

  const handleAssociateToken = async (tokenId: string) => {
    if (!walletInterface || !accountId) {
      console.log('No wallet connected');
      return;
    }
    
    try {
      console.log('Associating token:', tokenId);
      const txId = await walletInterface.associateToken(TokenId.fromString(tokenId));
      console.log('Association transaction:', txId);
      
      if (txId) {
        // Wait a bit for the transaction to be processed before rechecking
        setTimeout(async () => {
          await checkTokenAssociation(tokenId);
        }, 2000);
      }
    } catch (error) {
      console.error('Error associating token:', error);
    }
  };

  const isCampaignEnded = (campaign: Campaign) => {
    const endTimeDate = typeof campaign.endTime === 'string' 
      ? new Date(campaign.endTime)
      : new Date(parseInt(campaign.endTime.$date.$numberLong));
    
    return campaign.status === 'finished' || endTimeDate < new Date();
  };

  // Add this helper function at the component level
  const hasRequiredBalance = (tokenId: string, requiredAmount: number, decimals: number = 8) => {
    const balance = tokenBalances[tokenId];
    // Convert both amounts to the same decimal basis
    const requiredAmountWithDecimals = requiredAmount * Math.pow(10, decimals);
    return balance !== null && balance !== undefined && balance >= requiredAmountWithDecimals;
  };

  const hasRequiredTokenGatingBalance = (balance: number | null, requirement: TokenGatingRequirement): boolean => {
    if (balance === null) return false;
    
    // The balance from mirrorNode is already in the correct decimal format
    // The requirement.amount from MongoDB is already including decimals
    return balance >= requirement.amount;
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (!campaign) {
    return <Typography color="#ff004e">Campaign not found</Typography>;
  }

  const creatorHandle = campaign?.twitterData?.creatorTwitterHandle;

  return (
    <Box sx={{
      backgroundImage: `url(${serBackground})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
    }}>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4, flex: 1 }}>
        <Paper elevation={3} sx={{ p: 3, backgroundColor: 'white', border: '1px solid #ff004e', borderRadius: '8px' }}>
          <Button
            onClick={() => navigate('/')}
            startIcon={<ArrowBackIcon sx={{ color: '#ff004e' }} />}
            sx={{
              color: '#ff004e',
              mb: 2,
              textDecoration: 'none',
              padding: '0 0 2px 0',
              '&:hover': { backgroundColor: 'transparent' },
              '& .MuiButton-startIcon': { marginRight: 0.5 },
              '&::after': {
                content: '""',
                position: 'absolute',
                left: 0,
                right: 0,
                bottom: 1,
                height: '1px',
                backgroundColor: '#ff004e',
              },
            }}
          >
            BACK
          </Button>

          <Grid container spacing={3}>
            
            {/* Left Column - Scoreboard */}
            <Grid item xs={12} md={8}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 2 }}>
                <Box>
                  <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#ff004e', mb: 1 }}>
                    {campaign.name}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', color: '#ff004e' }}>
                    <Typography 
                      variant="body2" 
                      sx={{ 
                        mr: 1, 
                        cursor: 'pointer', 
                        '&:hover': { textDecoration: 'underline' } 
                      }}
                      onClick={() => handleCreatorClick(creatorHandle)}
                    >
                      Created by {creatorHandle}
                    </Typography>
                    <Typography variant="body2" sx={{ mx: 1 }}>|</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <NetworkLogo 
                        src={`/network-logos/${campaign.network.toLowerCase()}.png`} 
                        alt={campaign.network} 
                      />
                      <Typography variant="body2">{campaign.network}</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ textAlign: 'right' }}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#ff004e', mb: 1 }}>
                    {getTimeLeft(campaign.endTime)}
                  </Typography>
                  <Button 
                    variant="outlined" 
                    startIcon={<ShareIcon />} 
                    onClick={handleShare}
                    sx={{ 
                      color: '#ff004e', 
                      borderColor: '#ff004e',
                      borderRadius: '20px',
                      '&:hover': {
                        backgroundColor: 'rgba(255, 0, 78, 0.04)',
                        borderColor: '#ff004e',
                      }
                    }}
                  >
                    SHARE
                  </Button>
                </Box>
              </Box>
              <Box sx={{ mt: 2, mb: 3 }}>
                <Button
                  variant="contained"
                  onClick={handleOptIn}
                  disabled={isOptedIn || isOptingIn || !accountId || !isAuthenticated || isCampaignEnded(campaign)}
                  sx={{
                    backgroundColor: !accountId || !isAuthenticated ? 'grey.500' : 
                                    isOptedIn || isCampaignEnded(campaign) ? 'grey.500' : '#ff004e',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: !accountId || !isAuthenticated ? 'grey.600' : 
                                      isOptedIn || isCampaignEnded(campaign) ? 'grey.600' : '#ff0066',
                    },
                    '&.Mui-disabled': {
                      backgroundColor: isOptedIn ? 'grey.500' : 'grey.400',
                      color: 'white',
                    }
                  }}
                >
                  {isOptingIn ? 'Opting In...' : 
                   !accountId ? 'Connect Wallet to Opt In' :
                   !isAuthenticated ? 'Authenticate to Opt In' :
                   isOptedIn ? 'Opted In' : 
                   isCampaignEnded(campaign) ? 'Campaign Ended' : 'Opt In'}
                </Button>
                {optInError && (
                  <Typography variant="caption" sx={{ display: 'block', mt: 1, color: 'error.main' }}>
                    {optInError}
                  </Typography>
                )}
              </Box>

              <Box 
                sx={{ 
                  backgroundColor: 'rgba(255, 0, 78, 0.05)', 
                  p: 2, 
                  mb: 3, 
                  borderRadius: '8px',
                  border: '1px dashed #ff004e'
                }}
              >
                <Typography variant="body2" sx={{ color: 'grey.600' }}>
                  ⚠️ Note: You score will be updated once per day. <br /><br />  
                  {campaign.metrics?.twitter?.lastUpdated && (
                    <>
                       Last updated: {new Date(campaign.metrics.twitter.lastUpdated).toLocaleString(undefined, {
                        dateStyle: 'medium',
                        timeStyle: 'short'
                      })}
                    </>
                  )}
                </Typography>
              </Box>

              <Box sx={{ mt: 4 }}>
                <Paper 
                  elevation={2} 
                  sx={{ 
                    p: 3, 
                    border: '1px solid #ff004e', 
                    borderRadius: '8px',
                    background: 'linear-gradient(145deg, rgba(255,0,78,0.02) 0%, rgba(255,255,255,1) 100%)'
                  }}
                >
                  <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#ff004e', mb: 2 }}>
                    HOW TO PARTICIPATE
                  </Typography>
                  
                  <Box sx={{ mb: 3 }}>
                    <Typography variant="body1" sx={{ color: 'grey.700', mb: 2 }}>
                      Engage with @{campaign.twitterData?.creatorTwitterHandle} on X to increase your score:
                    </Typography>
                    <Box sx={{ pl: 2 }}>
                      <Typography variant="body1" sx={{ color: 'grey.700', mb: 1 }}>
                        • Reply to posts with meaningful comments
                      </Typography>
                      <Typography variant="body1" sx={{ color: 'grey.700', mb: 1 }}>
                        • Retweet/Quote content you find valuable
                      </Typography>
                      
                    </Box>
                    <Typography variant="body1" sx={{ color: 'grey.700', mb: 2 }}><br />
                    Remember: Our algorithm rewards genuine engagement and discourages spammy interactions. Quality counts!</Typography>
                  </Box>

                  <Button
                    variant="contained"
                    href={`https://twitter.com/${campaign.twitterData?.creatorTwitterHandle}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      backgroundColor: '#ff004e',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: '#d3003f',
                      },
                      px: 4
                    }}
                  >
                    Visit @{campaign.twitterData?.creatorTwitterHandle} on X
                  </Button>

                  {/* <Typography 
                    variant="body2" 
                    sx={{ 
                      color: 'grey.600', 
                      mt: 3,
                      fontStyle: 'italic'
                    }}
                  >
                    Note: Your interactions are tracked daily. Engage naturally and consistently for the best results.
                  </Typography> */}
                </Paper>
              </Box>
              <Box sx={{ mt: 4 }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#ff004e', mb: 1 }}>
                  SCOREBOARD
                </Typography>
                {campaign.metrics?.twitter?.lastUpdated && (
                  <Typography sx={{ 
                    color: 'grey.600',
                    fontSize: '0.85rem',
                    mb: 2
                  }}>
                    Last updated: {new Date(campaign.metrics.twitter.lastUpdated).toLocaleString(undefined, {
                      dateStyle: 'medium',
                      timeStyle: 'short'
                    })}
                  </Typography>
                )}
                
                <TableContainer component={Paper} sx={{ 
                  mb: 2, 
                  boxShadow: 'none',
                  '& .MuiTable-root': {
                    borderSpacing: '0',
                  }
                }}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <StyledTableHeaderCell>RANK</StyledTableHeaderCell>
                        <StyledTableHeaderCell>USER</StyledTableHeaderCell>
                        <StyledTableHeaderCell>SCORE</StyledTableHeaderCell>
                        <StyledTableHeaderCell>MULTIPLIER</StyledTableHeaderCell>
                        <StyledTableHeaderCell>CURRENT PRIZE</StyledTableHeaderCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {leaderboard.map((entry) => (
                        <TableRow 
                          key={entry._id}
                          sx={{ 
                            backgroundColor: entry.accountId === accountId ? 'rgba(255, 0, 78, 0.04)' : 'inherit',
                            '&:hover': { backgroundColor: 'rgba(255, 0, 78, 0.04)' }
                          }}
                        >
                          <StyledTableCell>{entry.rank}</StyledTableCell>
                          <StyledTableCell>
                            <Link
                              href={`https://x.com/${entry.twitterHandle}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              sx={{
                                color: '#ff004e',
                                textDecoration: 'none',
                                '&:hover': {
                                  textDecoration: 'underline',
                                },
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1
                              }}
                            >
                              @{entry.twitterHandle}
                            </Link>
                          </StyledTableCell>
                          <StyledTableCell>
                            {entry.score === null ? '-' : Number(entry.score).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            })}
                          </StyledTableCell>
                          <StyledTableCell>
                            {typeof entry.multiplier === 'object' 
                              ? `${Number(entry.multiplier.current).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2
                                })}x` 
                              : entry.multiplier 
                                ? `${Number(entry.multiplier).toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                  })}x` 
                                : '-'}
                          </StyledTableCell>
                          <StyledTableCell>
                            {entry.prizes?.share.map((prize, prizeIndex) => {
                              const prizePotEntry = campaign?.prizes?.sharePrizes?.find(p => p.tokenId === prize.token_id);
                              const amount = prizePotEntry?.customFee 
                                ? calculateAmountAfterFee(prize.amount || 0, prizePotEntry.customFee)
                                : (prize.amount || 0);
                              
                              const displayValue = isNaN(amount) || amount === 0 
                                ? '-' 
                                : `${Number(amount).toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                  })} ${prize.token_name}`;
                              
                              return (
                                <Typography key={prizeIndex} variant="body2">
                                  {displayValue}
                                </Typography>
                              );
                            })}
                          </StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'center',
                mt: 3,
                mb: 2 
              }}>
                <Pagination 
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="primary"
                  disabled={isLoading}
                  sx={{
                    '& .MuiPaginationItem-root': {
                      color: '#ff004e',
                      '&.Mui-selected': {
                        backgroundColor: '#ff004e',
                        color: 'white',
                        '&:hover': {
                          backgroundColor: '#d3003f',
                        }
                      },
                      '&:hover': {
                        backgroundColor: 'rgba(255, 0, 78, 0.04)',
                      }
                    }
                  }}
                />
                {isLoading && (
                  <CircularProgress 
                    size={24} 
                    sx={{ 
                      ml: 2,
                      color: '#ff004e'
                    }} 
                  />
                )}
              </Box>

              {/* <Box sx={{ mt: 4 }}>
                {campaign.content?.tweets && (
                  <CampaignTweets 
                    tweets={campaign.content.tweets}
                    userInteractions={userInteractions}
                    accountId={accountId}
                  />
                )}
              </Box> */}

              
            </Grid>

            {/* Right Column - Campaign Details */}
            <Grid item xs={12} md={4}>
              <Paper 
                elevation={2} 
                sx={{ 
                  p: 2, 
                  mb: 3, 
                  border: '1px solid #ff004e', 
                  borderRadius: '8px',
                  transition: 'all 0.3s ease-in-out',
                  ...(isClaimable && {
                    boxShadow: '0 0 15px rgba(255, 0, 78, 0.2)',
                    borderWidth: '2px',
                    background: 'linear-gradient(145deg, rgba(255,0,78,0.03) 0%, rgba(255,255,255,1) 100%)'
                  })
                }}
              >
                <Typography 
                  variant="h6" 
                  sx={{ 
                    fontWeight: 'bold', 
                    color: '#ff004e', 
                    mb: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  PRIZE POT
                  {isClaimable && (
                    <Chip 
                      label="Claiming Available" 
                      size="small"
                      sx={{
                        backgroundColor: 'rgba(255, 0, 78, 0.1)',
                        color: '#ff004e',
                        borderRadius: '4px',
                        '& .MuiChip-label': {
                          fontWeight: 600
                        }
                      }}
                    />
                  )}
                </Typography>
                
                <List>
                  {campaign.prizes?.sharePrizes?.map((prize, index) => (
                    <ListItem key={index}>
                      <ListItemText 
                        primary={
                          <Box>
                            {(userCampaignData?.prizes?.share?.[index]?.amount ?? 0) > 0 ? (
                              <>
                                <Typography sx={{ color: '#ff004e', fontWeight: 'bold', fontSize: '1.1rem' }}>
                                  Your current prize: {prize.customFee 
                                    ? roundDownPrizeAmount(calculateAmountAfterFee(userCampaignData?.prizes?.share?.[index]?.amount ?? 0, prize.customFee))
                                    : roundDownPrizeAmount(userCampaignData?.prizes?.share?.[index]?.amount ?? 0)
                                  } {prize.name}
                                </Typography>
                                <Typography sx={{ color: 'grey.600', fontSize: '0.9rem', mt: 0.5 }}>
                                  Total Pool: {prize.customFee 
                                    ? roundDownPrizeAmount(calculateAmountAfterFee(prize.amount, prize.customFee))
                                    : roundDownPrizeAmount(prize.amount)
                                  } {prize.name}
                                </Typography>
                              </>
                            ) : (
                              <Typography sx={{ color: '#ff004e' }}>
                                Total Prize Pool: <br /> {prize.customFee 
                                  ? roundDownPrizeAmount(calculateAmountAfterFee(prize.amount, prize.customFee))
                                  : roundDownPrizeAmount(prize.amount)
                                } {prize.name}
                              </Typography>
                            )}
                          </Box>
                        }
                        secondary={prize.tokenId !== 'HBAR' ? null : null}
                        secondaryTypographyProps={{ color: '#ff004e' }}
                      />
                      {accountId && (
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                          {prize.tokenId !== 'HBAR' && !tokenAssociations[prize.tokenId] && 
                           !(userCampaignData?.prizes?.share as PrizeShare[])?.some(p => 
                             p.token_id === prize.tokenId && 
                             p.claimStatus === 'claimed'
                           ) ? (
                            <Button
                              variant="outlined"
                              size="small"
                              onClick={() => handleAssociateToken(prize.tokenId)}
                              sx={{
                                color: '#ff004e',
                                borderColor: '#ff004e',
                                '&:hover': {
                                  borderColor: '#ff004e',
                                  backgroundColor: 'rgba(255, 0, 78, 0.04)',
                                }
                              }}
                            >
                              Associate
                            </Button>
                          ) : (
                            <Button 
                              variant="contained" 
                              onClick={() => handleClaim(prize.tokenId)}
                              disabled={
                                !isClaimable || 
                                claimLoadingStates[prize.tokenId] || 
                                (userCampaignData?.prizes?.share as PrizeShare[])?.some(p => 
                                  p.token_id === prize.tokenId && 
                                  p.claimStatus === 'claimed'
                                )
                              }
                              sx={{ 
                                backgroundColor: (userCampaignData?.prizes?.share as PrizeShare[])?.some(p => 
                                  p.token_id === prize.tokenId && 
                                  p.claimStatus === 'claimed'
                                ) ? 'grey.500' : '#ff004e',
                                '&:hover': {
                                  backgroundColor: (userCampaignData?.prizes?.share as PrizeShare[])?.some(p => 
                                    p.token_id === prize.tokenId && 
                                    p.claimStatus === 'claimed'
                                  ) ? 'grey.600' : '#d3003f',
                                },
                                '&:disabled': {
                                  backgroundColor: 'grey.500',
                                  color: 'white',
                                }
                              }}
                            >
                              {claimLoadingStates[prize.tokenId] ? (
                                <CircularProgress size={24} color="inherit" />
                              ) : (userCampaignData?.prizes?.share as PrizeShare[])?.some(p => 
                                  p.token_id === prize.tokenId && 
                                  p.claimStatus === 'claimed'
                                ) ? (
                                'Claimed'
                              ) : (
                                'Claim'
                              )}
                            </Button>
                          )}
                          
                          {(userCampaignData?.prizes?.share as PrizeShare[])?.find(p => 
                            p.token_id === prize.tokenId && 
                            p.claimTx?.txId
                          )?.claimTx?.txId && (
                            <Link
                              href={getHashscanLink((userCampaignData?.prizes?.share as PrizeShare[])?.find(p => 
                                p.token_id === prize.tokenId
                              )?.claimTx?.txId || '')}
                              target="_blank"
                              rel="noopener noreferrer"
                              sx={{ 
                                mt: 1,
                                color: '#ff004e',
                                fontSize: '0.75rem',
                                textDecoration: 'none',
                                display: 'block',
                                textAlign: 'right',
                                '&:hover': {
                                  textDecoration: 'underline'
                                }
                              }}
                            >
                              View on Hashscan
                            </Link>
                          )}
                          
                          {claimErrors[prize.tokenId] && (
                            <Typography variant="caption" sx={{ 
                              display: 'block', 
                              mt: 1, 
                              color: 'error.main' 
                            }}>
                              {claimErrors[prize.tokenId]}
                            </Typography>
                          )}
                        </Box>
                      )}
                    </ListItem>
                  ))}
                </List>
              </Paper>

              <Paper 
                elevation={2} 
                sx={{ 
                  p: 2, 
                  mb: 3, 
                  border: '1px solid #ff004e', 
                  borderRadius: '8px',
                  background: 'linear-gradient(145deg, rgba(255,0,78,0.02) 0%, rgba(255,255,255,1) 100%)'
                }}
              >
                <Typography 
                  variant="h6" 
                  sx={{ 
                    fontWeight: 'bold', 
                    color: '#ff004e', 
                    mb: 1,
                    fontSize: '1.1rem'
                  }}
                >
                  TOKEN GATING
                </Typography>
                {campaign.tokenGating && (campaign.tokenGating as TokenGatingRequirement[]).length > 0 && (
                  <Typography sx={{ 
                    color: 'grey.600',
                    fontSize: '0.85rem',
                    mb: 2
                  }}>
                    Score will be zero if token gating requirements aren't fulfilled
                    {(campaign.tokenGating as TokenGatingRequirement[]).length > 1 && 
                      ". Only one of the following requirements needs to be met"
                    }.
                  </Typography>
                )}

                {campaign.tokenGating && (campaign.tokenGating as TokenGatingRequirement[]).length > 0 ? (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    {(campaign.tokenGating as TokenGatingRequirement[]).map((req, index) => {
                      const balance = tokenBalances[req.tokenId];
                      const meetsRequirement = hasRequiredTokenGatingBalance(balance, req);
                      
                      return (
                        <Box 
                          key={index}
                          sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            gap: 1.5,
                            pl: 0 // Removed padding
                          }}
                        >
                          <Chip 
                            label={meetsRequirement ? "✓" : "×"}
                            size="small"
                            sx={{
                              backgroundColor: meetsRequirement ? 'rgba(76, 175, 80, 0.1)' : 'rgba(255, 0, 78, 0.1)',
                              color: meetsRequirement ? '#4caf50' : '#ff004e',
                              height: '24px',
                              width: '24px',
                              borderRadius: '12px',
                              '& .MuiChip-label': {
                                padding: 0,
                                fontSize: '16px',
                                fontWeight: 'bold'
                              }
                            }}
                          />
                          <Box>
                            <Link
                              href={req.type === 'nft' 
                                ? `https://sentx.io/nft-marketplace/${req.tokenId}` 
                                : `https://www.saucerswap.finance/swap/HBAR/${req.tokenId}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              sx={{ 
                                color: '#ff004e',
                                textDecoration: 'none',
                                fontWeight: 'bold',
                                cursor: 'pointer',
                                '&:hover': {
                                  textDecoration: 'underline'
                                }
                              }}
                            >
                              {req.name}
                              <Typography 
                                component="span" 
                                sx={{ 
                                  color: 'grey.600',
                                  ml: 1,
                                  fontSize: '0.9rem'
                                }}
                              >
                                ({req.type === 'nft' ? balance : formatTokenAmount(balance || 0, req.decimals)})
                              </Typography>
                            </Link>
                            <Typography variant="body2" sx={{ color: 'grey.600', mt: 0.5 }}>
                              Required: {req.amount.toLocaleString()} {req.symbol}
                            </Typography>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                ) : (
                  <Typography variant="body2" sx={{ color: 'grey.600', textAlign: 'center' }}>
                    No token gating requirements for this campaign
                  </Typography>
                )}
              </Paper>

              <Paper 
                elevation={2} 
                sx={{ 
                  p: 2, 
                  mb: 3, 
                  border: '1px solid #ff004e', 
                  borderRadius: '8px',
                  background: 'linear-gradient(145deg, rgba(255,0,78,0.02) 0%, rgba(255,255,255,1) 100%)'
                }}
              >
                <Typography 
                  variant="h6" 
                  sx={{ 
                    fontWeight: 'bold', 
                    color: '#ff004e', 
                    mb: 2,
                    fontSize: '1.1rem'
                  }}
                >
                  MULTIPLIERS
                </Typography>
                
                <Typography 
                  sx={{ 
                    color: 'grey.600',
                    fontSize: '0.85rem',
                    mb: 2
                  }}
                >
                  {campaign.maxMultiplier && campaign.maxMultiplier !== 'none' 
                    ? `Max multiplier: ${campaign.maxMultiplier}x` 
                    : 'No max multiplier'}
                </Typography>

                {campaign.multipliers && campaign.multipliers.length > 0 ? (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    {campaign.multipliers.map((multiplier, index) => {
                      const balance = !isLPMultiplier(multiplier) ? tokenBalances[multiplier.tokenId] || 0 : null;
                      const actualBalance = multiplier.type === 'nft' 
                        ? balance 
                        : isLPMultiplier(multiplier)
                          ? null
                          : balance !== null ? balance / Math.pow(10, isFungibleMultiplier(multiplier) ? multiplier.decimals : 8) : null;
                      
                      return (
                        <Box key={index} sx={{ display: 'flex', alignItems: 'flex-start', gap: 1.5 }}>
                          <Chip 
                            label={isLPMultiplier(multiplier) ? "?" : actualBalance && actualBalance > 0 ? "✓" : "×"}
                            size="small"
                            sx={{
                              backgroundColor: isLPMultiplier(multiplier)
                                ? 'rgba(255, 152, 0, 0.1)' 
                                : actualBalance && actualBalance > 0 
                                  ? 'rgba(76, 175, 80, 0.1)' 
                                  : 'rgba(255, 0, 78, 0.05)',
                              color: isLPMultiplier(multiplier)
                                ? '#ff9800'
                                : actualBalance && actualBalance > 0 
                                  ? '#4caf50' 
                                  : '#666',
                              height: '24px',
                              width: '24px',
                              borderRadius: '12px',
                              '& .MuiChip-label': {
                                padding: 0,
                                fontSize: '16px',
                                fontWeight: 'bold'
                              }
                            }}
                          />
                          <Box>
                            {isLPMultiplier(multiplier) ? (
                              <>
                                <Link
                                href={multiplier.tokenId 
                                  ? `https://www.saucerswap.finance/liquidity/${multiplier.tokenId}`
                                  : 'https://www.saucerswap.finance/liquidity'}
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{ 
                                  color: '#ff004e',
                                  textDecoration: 'none',
                                  fontWeight: 'bold',
                                  '&:hover': {
                                    textDecoration: 'underline'
                                  }
                                }}
                              >
                                {multiplier.name}
                              </Link>
                              <Typography variant="caption" sx={{ display: 'block', color: 'grey.600', mt: 0.5 }}>
                                Not tracked on UI
                              </Typography>
                            </>
                            ) : (
                              <Link
                                href={multiplier.type === 'nft' 
                                  ? `https://sentx.io/nft-marketplace/${multiplier.tokenId}` 
                                  : `https://www.saucerswap.finance/swap/HBAR/${multiplier.tokenId}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{ 
                                  color: '#ff004e',
                                  textDecoration: 'none',
                                  fontWeight: 'bold',
                                  '&:hover': {
                                    textDecoration: 'underline'
                                  }
                                }}
                              >
                                {multiplier.name}
                                {(isFungibleMultiplier(multiplier) || multiplier.type === 'nft') && actualBalance !== null && (
                                  <Typography 
                                    component="span" 
                                    sx={{ 
                                      color: 'grey.600',
                                      ml: 1,
                                      fontSize: '0.9rem'
                                    }}
                                  >
                                    ({actualBalance.toLocaleString()})
                                  </Typography>
                                )}
                              </Link>
                            )}
                            
                            {multiplier.type === 'nft' ? (
                              <>
                                <Typography variant="body2" sx={{ color: 'grey.600', mt: 0.5 }}>
                                  Required: {multiplier.amount} NFT(s)
                                </Typography>
                                <Typography variant="body2" sx={{ 
                                  color: '#ff004e',
                                  mt: 0.5
                                }}>
                                  +{multiplier.multiplier.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                  })}x
                                </Typography>
                              </>
                            ) : (
                              <Box sx={{ mt: 1 }}>
                                {(isFungibleMultiplier(multiplier) || multiplier.type === 'lp') && multiplier.tiers?.map((tier, tierIndex) => (
                                  <Typography 
                                    key={tierIndex}
                                    variant="body2" 
                                    sx={{ 
                                      color: multiplier.type === 'lp' ? 'grey.600' 
                                        : actualBalance !== null && actualBalance >= tier.amount ? '#ff004e' 
                                        : 'grey.600',
                                      mt: 0.5,
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                      '& > span:first-of-type': {
                                        flex: 1,
                                      },
                                      '& > span:last-child': {
                                        minWidth: '45px',
                                        textAlign: 'right',
                                        marginLeft: '8px'
                                      }
                                    }}
                                  >
                                    <span>{tier.amount.toLocaleString()} {multiplier.symbol}</span>
                                    <span>+{tier.multiplier.toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2
                                    })}x</span>
                                  </Typography>
                                ))}
                              </Box>
                            )}
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                ) : (
                  <Typography variant="body2" sx={{ color: 'grey.600', textAlign: 'center' }}>
                    No multipliers for this campaign
                  </Typography>
                )}
              </Paper>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </Box>
  );
};

export default CampaignDetails;